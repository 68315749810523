
import { Component, Vue } from 'vue-property-decorator'
import { mapActions, mapGetters } from 'vuex'
import MemberProvider from '@/resources/member.provider'
import CountriesData from '@/assets/json/Countries.json'

const memberService = new MemberProvider()

@Component({
  methods: {
    ...mapActions({
      setNationality: 'Cart/setNationality',
      setGender: 'Cart/setGender',
      setPayment: 'Cart/setPayment',
      ccSetPayment: 'ClickCollect/setPayment',
      exoSetPayment: 'ExchangeOrder/setPayment'
    })
  },
  computed: {
    ...mapGetters({
      net: 'Cart/net',
      nationality: 'Cart/nationality',
      gender: 'Cart/gender',
      tel: 'Cart/tel',
      ccNet: 'ClickCollect/net',
      exoNet: 'ExchangeOrder/payMore'
    })
  }
})
export default class Payment extends Vue {
  readonly setNationality!: any

  readonly setGender!: any

  readonly setPayment!: any

  readonly ccSetPayment!: any

  readonly exoSetPayment!: any

  public readonly net!: any

  public readonly nationality!: any

  public readonly gender!: any

  public readonly tel!: any

  public readonly ccNet!: any

  public readonly exoNet!: any

  public memberData: any = null

  public nationalities = [
    {
      nation: 'Thailand',
      text: 'ไทย'
    },
    {
      nation: 'Hong Kong',
      text: 'ฮ่องกง'
    },
    {
      nation: 'Taiwan',
      text: 'ไต้หวัน'
    },
    {
      nation: 'Macau',
      text: 'มาเก๊า'
    },
    {
      nation: 'Singapore',
      text: 'สิงคโปร์'
    },
    {
      nation: 'China',
      text: 'จีน'
    },
    {
      nation: 'Vietnam',
      text: 'เวียดนาม'
    },
    {
      nation: 'Malaysia',
      text: 'มาเลเซีย'
    },
    {
      nation: 'Philippines',
      text: 'ฟิลิปปินส์'
    },
    {
      nation: 'Laos',
      text: 'ลาว'
    },
    {
      nation: 'Myanmar',
      text: 'เมียนม่า'
    },
    {
      nation: 'Indonesia',
      text: 'อินโดนีเซีย'
    },
    {
      nation: 'Cambodia',
      text: 'กัมพูชา'
    },
    {
      nation: 'Russia',
      text: 'รัสเซีย'
    },
    {
      nation: 'Ukraine',
      text: 'ยูเครน'
    }
  ]

  public genders = [
    {
      text: 'ชาย',
      value: 'male'
    },
    {
      text: 'หญิง',
      value: 'female'
    }
  ]

  public nationInput = 'Thailand'

  get type (): any {
    return this.$route.query.type ? this.$route.query.type : 'receipt'
  }

  get countries (): any {
    const filtered = CountriesData.filter((c) => (
      c.country
        .toString()
        .toLowerCase()
        .indexOf(this.nationInput.toLowerCase()) >= 0
    ))
    return filtered.map((c) => c.country)
  }

  async mounted (): Promise<void> {
    if (this.type === 'receipt') {
      const { data } = await memberService.getMemberByTel(this.tel)

      if (data) {
        this.memberData = data
      }
    }
  }

  selectNationality (nation: string, isRedirect = false): void {
    if (!nation) {
      return
    }

    const found = CountriesData.find((c) => c.country.toLowerCase() === nation.toLowerCase())

    if (!found && isRedirect) {
      this.$buefy.toast.open({ message: `ไม่พบประเทศ ${nation}`, type: 'is-danger' })
      return
    }

    this.setNationality(nation)
    this.nationInput = nation

    // if (isRedirect && this.gender) {
    //   this.$router.push({ name: 'Payment' })
    // }
  }

  selectGender (payload: string, isRedirect = false): void {
    if (!payload) {
      return
    }

    const found = this.genders.find((c) => c.value.toLowerCase() === payload.toLowerCase())

    if (!found && isRedirect) {
      this.$buefy.toast.open({ message: `ไม่พบเพศ ${payload}`, type: 'is-danger' })
      return
    }

    this.setGender(payload)

    // if (isRedirect && this.nationality) {
    //   this.$router.push({ name: 'Payment' })
    // }
  }

  gotoPayment (): void {
    if (!this.nationality || !this.gender) {
      return
    }

    this.$router.push({ name: 'Payment' })
  }

  checkout (channel: string): void {
    if (this.type === 'exchange-order') {
      this.exoSetPayment(channel)
      if (channel === 'cash') {
        this.$router.push({ name: 'CheckoutByCash', query: { type: 'exchange-order' } })
      } else if (channel === 'credit') {
        this.$router.push({ name: 'CheckoutByCard', query: { type: 'exchange-order' } })
      }
    } else if (this.type === 'clickcollect') {
      this.ccSetPayment(channel)
      if (channel === 'cash') {
        this.$router.push({ name: 'CheckoutByCash', query: { type: 'clickcollect' } })
      } else if (channel === 'credit') {
        this.$router.push({ name: 'CheckoutByCard', query: { type: 'clickcollect' } })
      }
    } else if (channel === 'cash') {
      this.setPayment(channel)
      this.$router.push({ name: 'CheckoutByCash', query: { type: 'receipt' } })
    } else if (channel === 'credit') {
      this.setPayment(channel)
      this.$router.push({ name: 'CheckoutByCard', query: { type: 'receipt' } })
    }
  }
}
