
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import { Component, Vue } from 'vue-property-decorator'
import { mapState } from 'vuex'
import NewReportProvider from '@/resources/new-report.provider'

const NewReportService = new NewReportProvider()

dayjs.extend(advancedFormat)
@Component({
  computed: {
    ...mapState({
      selectBranch: (state: any) => state.Store.selectBranch
    })
  }
})
export default class SaleProduct extends Vue {
  selectBranch!: any

  startDate = new Date(dayjs().subtract(1, 'h')
                .minute(0)
                .second(0)
                .format())

  endDate = new Date(dayjs().add(1, 'h')
                .minute(0)
                .second(0)
                .millisecond(0)
                .format())

  loading = false

  report = []

  mounted (): void {
    this.getData()
  }

  async getData (): Promise<void> {
    try {
      this.loading = true
      const { data } = await NewReportService.getReport({
        warehouse: this.selectBranch.warehouse.id,
        startDate: this.startDate,
        endDate: this.endDate
      })

      this.report = data
      this.loading = false
    } catch (error) {
      console.error(error)
    }
  }

  // eslint-disable-next-line class-methods-use-this
  formatDate (date: Date): string {
    return dayjs(date).format('DD-MM-YYYY HH:mm')
  }

  get warehouseName (): string {
    if (!this.selectBranch || this.selectBranch === null) {
      return ''
    }
    return this.selectBranch.warehouse.name
  }
}
