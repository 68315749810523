
import { Component, Vue } from 'vue-property-decorator'
import SelectStore from '@/components/select-branch.vue'
import { mapActions, mapState } from 'vuex'

@Component({
  components: {
    SelectStore
  },
  methods: {
    ...mapActions({
      clearCart: 'Cart/clearCart'
    })
  },
  computed: {
    ...mapState({
      selectBranch: (state: any) => state.Store.selectBranch,
      storeInfo: (state: any) => state.Store.storeInfo
    })
  }
})
export default class Home extends Vue {
  selectBranch!: any

  activeModal = false

  storeInfo!: any

  readonly clearCart!: any

  readonly allMenus: any[] = [
    {
      title: 'Product Stock',
      icon: 'tag-search-outline',
      to: 'ProductStock',
      color: 'gold-bg',
      showOnScreen: 'all',
      params: {},
      query: {}
    },
    {
      title: 'POS',
      icon: 'cash-register',
      to: 'ProductList',
      color: 'violet-bg',
      showOnScreen: 'all',
      params: {},
      query: {}
    },
    // {
    //   title: 'Desktop POS',
    //   icon: 'cash-register',
    //   to: 'DesktopCart',
    //   color: 'violet-bg',
    //   showOnScreen: 'desktop',
    //   params: {},
    //   query: {}
    // },
    // {
    //   title: 'EVENT',
    //   icon: 'sack',
    //   to: 'ProductEventList',
    //   color: 'gold-bg',
    //   showOnScreen: 'all',
    //   checkWarehouseCode: ['GW-EVE1'],
    //   params: {},
    //   query: {}
    // },
    {
      title: 'Pick Pack',
      icon: 'archive',
      to: 'PickPack',
      color: 'blue-bg',
      showOnScreen: 'all',
      params: {},
      query: {}
    },
    // {
    //   title: 'Click & Collect',
    //   icon: 'tshirt-crew',
    //   to: 'ScanQr',
    //   params: {},
    //   query: {
    //     type: 'clickcollect'
    //   }
    // },
    {
      title: 'User Info',
      icon: 'account',
      to: 'UserInfo',
      color: 'green-bg',
      showOnScreen: 'all',
      params: {},
      query: {}
    },
    // {
    //   title: 'เปลี่ยนของ',
    //   icon: 'swap-horizontal-bold',
    //   to: 'ExchangeOrder',
    //   color: 'dark-blue-bg',
    //   showOnScreen: 'all',
    //   params: {},
    //   query: {}
    // },
    {
      title: 'PMS',
      icon: 'chart-bar',
      to: 'PMS',
      color: 'orange-bg',
      showOnScreen: 'all',
      params: {},
      query: {}
    },
    {
      title: 'จำนวนของขาย',
      icon: 'tshirt-crew-outline',
      to: 'SaleProduct',
      color: 'pink-bg',
      showOnScreen: 'all',
      params: {},
      query: {}
    },
    {
      title: 'ยอดขายประจำวัน',
      icon: 'basket-fill',
      to: 'DailyOrder',
      color: 'sun-bg',
      showOnScreen: 'all',
      params: {},
      query: {}
    },
    {
      title: 'ปิดยอด',
      icon: 'package-variant-closed',
      to: 'Summary',
      color: 'teal-bg',
      showOnScreen: 'all',
      params: {},
      query: {}
    },
    {
      title: 'เปลี่ยนสาขา',
      icon: 'store',
      action: this.actionActiveModal,
      color: 'cyan-bg',
      showOnScreen: 'all',
      params: {},
      query: {}
    },
    // {
    //   title: 'Re Print',
    //   icon: 'printer',
    //   to: 'RePrintReceipt',
    //   color: 'pink-bg',
    //   showOnScreen: 'desktop',
    //   params: {},
    //   query: {}
    // },
    {
      title: 'Logout',
      icon: 'logout',
      to: 'Logout',
      color: 'blue-grey-bg',
      showOnScreen: 'all',
      params: {},
      query: {}
    }
  ]

  get menus (): any[] {
    const baseMenu = this.allMenus.filter((menu) => !menu.checkWarehouseCode).filter(
      (menu) => (menu.showOnScreen === 'all')
        || (this.isMobile && menu.showOnScreen === 'mobile')
        || (!this.isMobile && menu.showOnScreen === 'desktop')
    )
    const additionalMenu = this.allMenus.filter(
      (menu) => menu.checkWarehouseCode
        && menu.checkWarehouseCode.includes(this.selectBranch?.warehouse?.code || 'NONE')
    )

    return [...additionalMenu, ...baseMenu]
  }

  actionActiveModal (): void {
    this.activeModal = true
  }

  get warehouseName (): string {
    if (!this.selectBranch || this.selectBranch === null) {
      return ''
    }
    return this.selectBranch.warehouse.name
  }

  get storeName (): string {
    return this.storeInfo?.name || ''
  }

  // eslint-disable-next-line class-methods-use-this
  get isMobile (): boolean {
    return window.innerWidth <= 768
  }

  closeModal (): void {
    this.activeModal = false
  }

  mounted (): void {
    this.clearCart()
    if (!this.selectBranch || this.selectBranch === null) {
      this.activeModal = true
    }
  }
}
