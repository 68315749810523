/* eslint-disable prefer-rest-params */
/* eslint-disable no-inner-declarations */
const gtagId = process.env.VUE_APP_GTAG_ID

if (gtagId) {
  const script = document.createElement('script')
  script.src = `https://www.googletagmanager.com/gtag/js?id=${gtagId}`
  script.async = true
  document.head.appendChild(script)

  const tmpWindow = Object.assign(window, { dataLayer: [] as any[] })

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function gtag (...args: any) {
    tmpWindow.dataLayer.push(arguments)
  }

  gtag('js', new Date())
  gtag('config', gtagId, { send_page_view: true })
} else {
  console.error('Google Analytics ID is not defined.')
}
