
import { Component, Prop, Vue } from 'vue-property-decorator'
import { mapActions, mapState } from 'vuex'

@Component({
  methods: {
    ...mapActions({
      getPrinters: 'Print/getPrinters',
      printProductPicking: 'Print/printProductPicking'
    })
  },
  computed: {
    ...mapState({
      printers: (state: any) => state.Print.printers
    })
  }
})
export default class ModalCallProduct extends Vue {
  @Prop()
  showModal!: boolean

  @Prop()
  product!: any

  readonly getPrinters!: any

  printers!: any[]

  readonly printProductPicking!: any

  // async mounted (): Promise<void> {
  //   await this.getPrinters()
  // }

  get isDisableBtn (): boolean {
    return this.selectPrinter === null || this.customerName === '' || !this.amount || this.amount === null
  }

  async print (): Promise<void> {
    const result = await this.printProductPicking({
      customerName: this.customerName,
      print: {
        ip: this.selectPrinter.ip
      },
      to: {
        id: this.selectLocation.id,
        name: this.selectLocation.name
      },
      item: {
        ...this.product,
        amount: this.amount
      }
    })

    if (result) {
      this.$buefy.toast.open({ message: 'Success', type: 'is-success' })
      this.$emit('onCloseModal')
    } else {
      this.$buefy.dialog.alert({
        title: 'Error',
        message: 'เกิดข้อผิพลาด !!',
        confirmText: 'ปิด',
        type: 'is-danger',
        hasIcon: true
      })
      this.$emit('onCloseModal')
    }
  }

  customerName = ''

  amount = 1

  selectPrinter: any = null

  selectLocation = {
    id: '1231',
    name: 'ห้องลองชั้น 2',
    warehouse: 1079
  }

  locations = [
    {
      id: '1231',
      name: 'ห้องลองชั้น 2',
      warehouse: 1079
    },
    {
      id: '1232',
      name: 'ห้องลองชั้น 3',
      warehouse: 1079
    },
    {
      id: '1233',
      name: 'Cashier ชั้น 2',
      warehouse: 1079
    },
    {
      id: '1234',
      name: 'Cashier ชั้น 3',
      warehouse: 1079
    }
  ]

  loading = false

  closeModal (): void {
    this.$emit('onCloseModal')
  }
}
