
import { Component, Vue } from 'vue-property-decorator'
import { mapActions, mapGetters } from 'vuex'

@Component({
  methods: {
    ...mapActions({
      createOrder: 'Cart/createOrder',
      ccCreateOrder: 'ClickCollect/createOrder',
      exoCreateOrder: 'ExchangeOrder/createOrder'
    })
  },
  computed: {
    ...mapGetters({
      net: 'Cart/net',
      ccNet: 'ClickCollect/net',
      exoNet: 'ExchangeOrder/payMore',
      payment: 'Cart/payment',
      ccPayment: 'ClickCollect/payment',
      exoPayment: 'ExchangeOrder/payment'
    })
  }
})
export default class CheckoutByCard extends Vue {
  readonly createOrder!: any

  readonly ccCreateOrder!: any

  readonly exoCreateOrder!: any

  private readonly ccNet!: any

  private readonly net!: any

  private readonly exoNet!: any

  ccPayment!: any

  exoPayment!: any

  payment!: any

  showPriceFormat!: any

  loading = false

  get type (): any {
    return this.$route.query.type ? this.$route.query.type : 'receipt'
  }

  get realNet (): any {
    if (this.type === 'clickcollect') {
      return this.ccNet
    }
    if (this.type === 'exchange-order') {
      return this.exoNet
    }
    return this.net
  }

  get realPayment (): any {
    if (this.type === 'clickcollect') {
      return this.ccPayment
    }
    if (this.type === 'exchange-order') {
      return this.exoPayment
    }
    return this.payment
  }

  async checkout (): Promise<void> {
    this.loading = true
    let result: any = {}
    if (this.type === 'clickcollect') {
      result = await this.ccCreateOrder()
    } else if (this.type === 'exchange-order') {
      result = await this.exoCreateOrder()
    } else {
      result = await this.createOrder()
    }
    this.loading = false
    if (result.success) {
      this.$buefy.dialog.confirm({
        message: 'Create Order success',
        canCancel: false,
        onConfirm: () => this.$router.push({ name: 'Print', query: { type: this.type } })
      })
    } else {
      let message = ''
      if (result.message.includes('[00]')) {
        message += 'ของไม่พอ : '
      }
      const splitMessage = result.message.split(' - ')
      message += splitMessage[splitMessage.length - 1]
      message += '<br />กลับไปลบของในตะกร้าออกก่อน หรือ ติดต่อผู้จัดการสาขา'
      this.$buefy.dialog.alert({
        title: 'เกิดปัญหา',
        message
      })
    }
  }
}
