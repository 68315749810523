
import { Component, Prop, Vue } from 'vue-property-decorator'
import { mapActions } from 'vuex'

@Component({
  methods: {
    ...mapActions({
      removeItems: 'Cart/removeItems'
    })
  }
})
export default class CartItems extends Vue {
  @Prop()
  readonly product!: any

  removeItems!: any

  showFullPriceFormat!: any

  calcStock (): number {
    return this.product.stock.onHandQty - this.product.stock.onReservedQty
  }
}

