
import { Component, Vue } from 'vue-property-decorator'
import { mapActions, mapState, mapGetters } from 'vuex'

const QRCode = require('qrcode')

const generateQRCode = async (url: string) => {
  try {
    return QRCode.toDataURL(url)
  } catch (err) {
    console.error(err)
    return null
  }
}

@Component({
  methods: {
    ...mapActions({
      getPrinters: 'Print/getPrinters',
      printReceipt: 'Print/printReceipt',
      clearCart: 'Cart/clearCart'
    })
  },
  computed: {
    ...mapState({
      printers: (state: any) => state.Print.printers,
      selectBranch: (state: any) => state.Store.selectBranch
    }),
    ...mapGetters({
      bill: 'Cart/bill',
      ccBill: 'ClickCollect/bill',
      exoBill: 'ExchangeOrder/bill',
      storeSetting: 'Store/storeSetting'
    })
  }
})
export default class Print extends Vue {
  getPrinters!: any

  printers!: any

  printReceipt!: any

  selectBranch!: any

  clearCart!: any

  bill!: any

  ccBill!: any

  exoBill!: any

  loading = false

  storeSetting!: any

  qrReceipt = ''

  async mounted (): Promise<void> {
    await this.getPrinters()
    this.qrReceipt = await generateQRCode(this.linkBill)
  }

  get linkBill (): string {
    return `https://${this.storeSetting.linkBill}/th/${this.bill.url}/e-receipt`
  }

  get type (): any {
    return this.$route.query.type ? this.$route.query.type : 'receipt'
  }

  get billId (): any {
    if (this.type === 'clickcollect') {
      return this.ccBill.orderId
    }
    if (this.type === 'exchange-order') {
      return this.exoBill.orderId
    }
    return this.bill.orderId
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async print (data: any): Promise<void> {
    this.loading = true
    const result = await this.printReceipt({ ...data, type: this.type })
    this.loading = false
    if (!result) {
      this.$buefy.dialog.alert('Error')
    } else {
      this.clearCart()
      this.$router.push({ name: 'Home' })
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async backToEvent (data: any): Promise<void> {
    this.loading = true
    const result = await this.printReceipt({ ...data, type: this.type })
    this.loading = false
    if (!result) {
      this.$buefy.dialog.alert('Error')
    } else {
      this.clearCart()
      this.$router.push({ name: 'ProductEventList' })
    }
  }
}
