
import { Component, Vue } from 'vue-property-decorator'
import { mapActions, mapGetters } from 'vuex'

@Component({
  methods: {
    ...mapActions({
      createOrder: 'Cart/createOrder',
      setCashAmount: 'Cart/setCashAmount',
      ccCreateOrder: 'ClickCollect/createOrder',
      ccSetCashAmount: 'ClickCollect/setCashAmount',
      exoCreateOrder: 'ExchangeOrder/createOrder',
      exoSetCashAmount: 'ExchangeOrder/setCashAmount'
    })
  },
  computed: {
    ...mapGetters({
      net: 'Cart/net',
      ccNet: 'ClickCollect/net',
      exoNet: 'ExchangeOrder/payMore'
    })
  }
})
export default class Home extends Vue {
  number = 0

  createOrder!: any

  ccCreateOrder!: any

  exoCreateOrder!: any

  setCashAmount!: any

  ccSetCashAmount!: any

  exoSetCashAmount!: any

  showPriceFormat!: any

  ccNet!: any

  net!: any

  exoNet!: any

  loading = false

  cashLists = [
    {
      amount: 1000,
      count: 0
    },
    {
      amount: 2000,
      count: 0
    },
    {
      amount: 3000,
      count: 0
    },
    {
      amount: 4000,
      count: 0
    },
    {
      amount: 5000,
      count: 0
    },
    {
      amount: 0,
      count: 0
    }
  ]

  get type (): any {
    return this.$route.query.type ? this.$route.query.type : 'receipt'
  }

  addCash (amount: number): void {
    if (amount === 0) {
      this.number = 0
      this.cashLists = this.cashLists.map((c) => ({
        ...c,
        count: 0
      }))
    } else {
      this.number += amount
      const index = this.cashLists.findIndex((c) => c.amount === amount)

      if (index !== -1) {
        this.cashLists[index].count++
      }
    }
  }

  get realNet (): any {
    if (this.type === 'clickcollect') {
      return this.ccNet
    }
    if (this.type === 'exchange-order') {
      return this.exoNet
    }
    return this.net
  }

  async checkout (): Promise<void> {
    this.loading = true
    let result: {
      success: boolean
      message: string
    }
    if (this.type === 'clickcollect') {
      this.ccSetCashAmount(this.number)
      result = await this.ccCreateOrder()
    } else if (this.type === 'exchange-order') {
      this.exoSetCashAmount(this.number)
      result = await this.exoCreateOrder()
    } else {
      this.setCashAmount(this.number)
      result = await this.createOrder()
    }
    this.loading = false
    if (result.success) {
      this.$buefy.dialog.confirm({
        message: 'Create Order success',
        canCancel: false,
        onConfirm: () => this.$router.push({ name: 'Print', query: { type: this.type } })
      })
    } else {
      let message = ''
      if (result.message.includes('[00]')) {
        message += 'ของไม่พอ : '
      }
      const splitMessage = result.message.split(' - ')
      message += splitMessage[splitMessage.length - 1]
      message += '<br />กลับไปลบของในตะกร้าออกก่อน หรือ ติดต่อผู้จัดการสาขา'
      this.$buefy.dialog.alert({
        title: 'เกิดปัญหา',
        message
      })
    }
  }
}
