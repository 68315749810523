
import { Component, Vue } from 'vue-property-decorator'
import { getAuthDecode } from '@/utils/auth.utils'
import { mapActions, mapState } from 'vuex'
import SelectStore from '@/components/select-branch.vue'

@Component({
  components: {
    SelectStore
  },
  computed: {
    ...mapState({
      selectBranch: (state: any) => state.Store.selectBranch
    })
  },
  methods: {
    ...mapActions({
      clearOrder: 'Order/clearOrder'
    })
  }
})
export default class UserInfo extends Vue {
  private clearOrder!: any

  selectBranch!: any

  user = getAuthDecode()

  activeModal = false

  mounted (): void {
    this.clearOrder()
  }

  closeModal (): void {
    this.activeModal = false
  }
}
