
import { Component, Vue } from 'vue-property-decorator'
import { mapActions } from 'vuex'
import axios from 'axios'
import { getAuthToken } from '@/utils/auth.utils'

@Component({
  methods: {
    ...mapActions({
      initStore: 'Store/initStore'
    })
  }
})
export default class App extends Vue {
  initStore!: () => any

  isUpToDate = true

  created (): void {
    // this.checkVersion()
    // setInterval(this.checkVersion, 10000)

    if (getAuthToken()) {
      this.initStore()
    }
  }

  async checkVersion (): Promise<void> {
    const now = Date.now()
    const { data } = await axios.get(`/version.txt?t=${now}`)
    const myWindow: any = window
    const clientScriptVersion = myWindow.campVersion.trim()
    const serverScriptVersion = data.trim()
    if (clientScriptVersion !== serverScriptVersion) {
      this.$buefy.dialog.alert({
        title: 'New Version Found.',
        message: 'Please click reload to update new version.',
        confirmText: 'Reload',
        onConfirm: () => {
          this.$router.push({ name: 'Logout' })
        },
        type: 'is-danger',
        hasIcon: true
      })
    }
  }
}
