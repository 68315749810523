
import { Component, Vue } from 'vue-property-decorator'
import SelectStore from '@/components/select-branch.vue'
import { mapGetters, mapState } from 'vuex'
import ProductProvider from '@/resources/product.provider'
import ProductStockList from '@/components/product-stock/product-list.vue'
import { mapProduct } from '@/utils/product.utils'
import DesktopPosNavbar from '@/components/desktop-pos/navbar.vue'
import keyCodeMapperCharacter from '@/utils/keycodemap.utils'
import * as onScan from 'onscan.js'

const ProductService = new ProductProvider()

@Component({
  components: {
    ProductStockList,
    SelectStore,
    DesktopPosNavbar
  },
  computed: {
    ...mapState({
      selectBranch: (state: any) => state.Store.selectBranch,
      storeInfo: (state: any) => state.Store.storeInfo,
      selectPos: (state: any) => state.Store.selectPos
    }),
    ...mapGetters({
      cartAmount: 'Cart/cartAmount'
    })
  }
})
export default class ProductListPage extends Vue {
  search = ''

  loading = false

  storeInfo!: any

  cartAmount!: any

  selectBranch!: any

  selectPos!: any

  products: any[] = []

  // eslint-disable-next-line class-methods-use-this
  mounted (): void {
    if (this.searchQuery) {
      this.search = `${this.searchQuery}`
      this.searchProduct()
      this.$router.replace({ query: {} })
    }
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const _this = this
    onScan.attachTo(document, {
        suffixKeyCodes: [13],
        reactToPaste: true,
        onScan (sCode) {
          const patternUrl = /^(https:\/\/www\.gentlewomanonline\.com\/|https:\/\/gentlewomanonline\.com\/|https:\/\/www\.matter-makers\.com\/|https:\/\/matter-makers\.com\/)/
          if (patternUrl.test(sCode.toLowerCase())) {
            const splitCodeFromUrl = sCode.split('/')
            const code = splitCodeFromUrl[splitCodeFromUrl.length - 1]
            if (code) {
              _this.search = code
              _this.searchProduct()
            }
          } else {
            _this.search = sCode
          }
        },
        keyCodeMapper (oEvent: any) {
          if (keyCodeMapperCharacter[+oEvent.keyCode] !== undefined) {
            return keyCodeMapperCharacter[+oEvent.keyCode][+oEvent.shiftKey]
          }
          return onScan.decodeKeyEvent(oEvent)
        }
    })
  }

  // eslint-disable-next-line class-methods-use-this
  beforeDestroy (): void {
    onScan.detachFrom(document)
  }

  get storeName (): string {
    return this.storeInfo?.name || 'STORE NOT FOUND'
  }

  get warehouseName (): string {
    if (!this.selectBranch || this.selectBranch === null) {
      return ''
    }
    return this.selectBranch.warehouse.name
  }

  get searchQuery (): string | (string | null)[] {
    return this.$route.query.search || ''
  }

  get focusQuery (): string | (string | null)[] {
    return this.$route.query.focus || ''
  }

  // eslint-disable-next-line class-methods-use-this
  get isMobile (): boolean {
    return window.matchMedia('(max-width: 768px)').matches
  }

  get productListClass (): string {
    return this.isMobile ? 'product-list' : ''
  }

  async searchProduct (): Promise<void> {
    this.loading = true
    const { data: { results } } = await ProductService.getProduct({ search: this.search })

      this.products = mapProduct(results, this.selectBranch, this.search)
    if (this.products.length === 0) {
      this.$buefy.toast.open({ message: 'Product not found', type: 'is-danger' })
    }
    this.loading = false
  }
}
