
import { saleTags } from '@/utils/cart.utils'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class ProductList extends Vue {
  @Prop()
  readonly product!: any

  showFullPriceFormat!: any

  addToCart (): void {
    this.$emit('onAddItem', this.product)
  }

  showPrice (): number {
    const isTag = this.product.tags && this.product.tags.length > 0

    const isDiscount100p = isTag && saleTags.discount100p.length > 0
    ? this.product.tags.some((tag: string) => saleTags.discount100p.some((dis) => dis.toUpperCase() === tag.toUpperCase()))
      : false

    const isDiscount70p = isTag && saleTags.discount70p.length > 0
      ? this.product.tags.some((tag: string) => saleTags.discount70p.some((dis) => dis.toUpperCase() === tag.toUpperCase()))
        : false

    const isDiscount80p = isTag && saleTags.discount80p.length > 0
      ? this.product.tags.some((tag: string) => saleTags.discount80p.some((dis) => dis.toUpperCase() === tag.toUpperCase()))
        : false

    const isDiscount60p = isTag && saleTags.discount60p.length > 0
      ? this.product.tags.some((tag: string) => saleTags.discount60p.some((dis) => dis.toUpperCase() === tag.toUpperCase()))
        : false

    const isDiscount50p = isTag && saleTags.discount50p.length > 0
      ? this.product.tags.some((tag: string) => saleTags.discount50p.some((dis) => dis.toUpperCase() === tag.toUpperCase()))
        : false

    const isDiscount85pFloor = isTag && saleTags.discount85pFloor.length > 0
      ? this.product.tags.some((tag: string) => saleTags.discount85pFloor.some((dis) => dis.toUpperCase() === tag.toUpperCase()))
        : false

    const isDiscount70pFloor = isTag && saleTags.discount70pFloor.length > 0
      ? this.product.tags.some((tag: string) => saleTags.discount70pFloor.some((dis) => dis.toUpperCase() === tag.toUpperCase()))
        : false

    const isDiscount50pFloor = isTag && saleTags.discount50pFloor.length > 0
      ? this.product.tags.some((tag: string) => saleTags.discount50pFloor.some((dis) => dis.toUpperCase() === tag.toUpperCase()))
        : false

    const isDiscount30pFloor = isTag && saleTags.discount30pFloor.length > 0
      ? this.product.tags.some((tag: string) => saleTags.discount30pFloor.some((dis) => dis.toUpperCase() === tag.toUpperCase()))
        : false

    const isDiscount40p = isTag && saleTags.discount40p.length > 0
      ? this.product.tags.some((tag: string) => saleTags.discount40p.some((dis) => dis.toUpperCase() === tag.toUpperCase())) : false
    const isDiscount30p = isTag && saleTags.discount30p.length > 0
      ? this.product.tags.some((tag: string) => saleTags.discount30p.some((dis) => dis.toUpperCase() === tag.toUpperCase())) : false
    const isDiscount20p = isTag && saleTags.discount20p.length > 0
      ? this.product.tags.some((tag: string) => saleTags.discount20p.some((dis) => dis.toUpperCase() === tag.toUpperCase())) : false
    const isDiscount400 = isTag && saleTags.discount400.length > 0
      ? this.product.tags.some((tag: string) => saleTags.discount400.some((dis) => dis.toUpperCase() === tag.toUpperCase())) : false

    if (isDiscount100p) { return 0 }
    if (isDiscount85pFloor) { return Math.floor(parseInt(this.product.price) * 0.15) }
    if (isDiscount80p) { return Math.round(parseInt(this.product.price) * 0.2) }
    if (isDiscount70p) { return Math.round(parseInt(this.product.price) * 0.3) }
    if (isDiscount70pFloor) { return Math.floor(parseInt(this.product.price) * 0.3) }
    if (isDiscount60p) { return Math.round(parseInt(this.product.price) * 0.4) }
    if (isDiscount50p) { return Math.round(parseInt(this.product.price) * 0.5) }
    if (isDiscount50pFloor) { return Math.floor(parseInt(this.product.price) * 0.5) }
    if (isDiscount40p) { return Math.round(parseInt(this.product.price) * 0.6) }
    if (isDiscount30p) { return Math.round(parseInt(this.product.price) * 0.7) }
    if (isDiscount30pFloor) { return Math.floor(parseInt(this.product.price) * 0.7) }
    if (isDiscount20p) { return Math.round(parseInt(this.product.price) * 0.8) }
    if (isDiscount400) { return parseInt(this.product.price) - 400 }
    return parseInt(this.product.price)
  }
}
