/* eslint-disable import/prefer-default-export */
import dayjs from 'dayjs'
import axios from 'axios'
import { getAuth } from '@/utils/auth.utils'

const groupProduct = (cartItems: any[], item: any) => {
  const results: any[] = []

  cartItems.forEach((cartItem) => {
    const index = results.findIndex((re) => re.id === cartItem.productId)
    const addItem = cartItem.productId === item?.productId
    if (index === -1) {
      results.push({
        id: cartItem.productId,
        skuId: cartItem.id,
        productId: cartItem.productId,
        amount: cartItem.amount,
        tags: addItem ? item.tags : cartItem.tags
      })
    } else {
      let tags = [...results[index].tags, ...cartItem.tags]
      tags = [...new Set(tags)]
      results[index].amount += cartItem.amount
      results[index].tags = addItem ? item.tags : tags
    }
  })

  return results
}

const groupAmountByTag = (cartItems: any[], tag: string) => {
  const filtered = cartItems.filter((v) => v.tags.includes(tag))
  const amount = filtered.reduce((sum, item) => sum + item.amount, 0)

  return amount
}

const groupSku = (cartItems: any[], item: any) => {
  const results: any[] = []

  cartItems.forEach((cartItem) => {
    const index = results.findIndex((re) => re.id === cartItem.id)
    const addItem = cartItem.id === item?.id
    if (index === -1) {
      results.push({
        id: cartItem.id,
        skuId: cartItem.id,
        productId: cartItem.productId,
        amount: cartItem.amount,
        tags: addItem ? item.tags : cartItem.tags
      })
    } else {
      let tags = [...results[index].tags, ...cartItem.tags]
      tags = [...new Set(tags)]
      results[index].amount += cartItem.amount
      results[index].tags = addItem ? item.tags : tags
    }
  })

  return results
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const CheckAddToCartMaximum = async (cartItems: any[], item: any, quantity = 1, type = 'increment'): Promise<any> => {
  let result = {
    isSuccess: true,
    message: 'สำเร็จ'
  }

  const baseURL = process.env.VUE_APP_API

  const { data: { data } } = await axios.get(`${baseURL}/web-cart-limitations?brand=all`, {
    headers: {
      Authorization: `Bearer ${getAuth()}`
    }
  })

  const config: any[] = []
  data.forEach((c: any) => {
    const seperateTags = c.tags.map((tag: string) => ({
      tag,
      startTime: dayjs(c.start_date).format(),
      endTime: dayjs(c.end_date).format(),
      message: {
        th: 'YOU HAVE REACHED THE PURCHASE LIMIT',
        en: 'YOU HAVE REACHED THE PURCHASE LIMIT'
      },
      cartMessage: {
        th: 'YOU HAVE REACHED THE PURCHASE LIMIT',
        en: 'YOU HAVE REACHED THE PURCHASE LIMIT'
      },
      isPerProduct: c.is_per_product,
      isPerTags: c.is_per_tags,
      amount: c.amount
    }))

    config.push(...seperateTags)
  })

  const addToCartConfig = config.find((c: any) => item.tags.includes(c.tag))

  if (cartItems.length === 0 || !addToCartConfig) {
    return result
  }

  const serverTime = new Date().getTime() + 1000

  const startTime = dayjs(addToCartConfig.startTime, 'YYYY-M-DD HH:mm:SS ZZ').valueOf()
  const endTime = dayjs(addToCartConfig.endTime, 'YYYY-M-DD HH:mm:SS ZZ').valueOf()

  if (serverTime < startTime || serverTime > endTime) {
    return result
  }

  if (addToCartConfig.isPerTags) {
    const items = groupProduct(cartItems, item)
    const grouped = groupAmountByTag(items, addToCartConfig.tag)
    const totalQuantity = type === 'increment' ? (grouped + quantity) : quantity
    if (totalQuantity > addToCartConfig.amount) {
      result = {
        isSuccess: false,
        message: `สินค้านี้ห้ามซื้อเกิน ${addToCartConfig.amount} ต่อบิล`
      }
    }
  } else {
    const grouped = addToCartConfig.isPerProduct
      ? groupProduct(cartItems, item).filter((p) => p.tags.some((t: any) => t === addToCartConfig.tag))
      : groupSku(cartItems, item).filter((p) => p.tags.some((t: any) => t === addToCartConfig.tag))

    if (grouped.length === 0) {
      return result
    }

    const cartProduct = addToCartConfig.isPerProduct
      ? grouped.find((g) => g.id === item.productId)
      : grouped.find((g) => g.id === item.id)

    const totalQuantity = cartProduct && type === 'increment' ? (cartProduct.amount + quantity) : quantity
    if (cartProduct && totalQuantity > addToCartConfig.amount) {
      result = {
        isSuccess: false,
        message: `สินค้านี้ห้ามซื้อเกิน ${addToCartConfig.amount} ต่อบิล`
      }
    }
  }

  return result
}
