
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})
export default class SkuList extends Vue {
  @Prop()
  sku!: any

  @Prop({ default: '฿' })
  currencySymbol!: any

  showFullPriceFormat!: any
}
